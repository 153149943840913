import React from 'react';
import { NavLink } from 'react-router-dom';
import { getTopLevelMenuItems } from '../../WebContent/logic/getContentData';
import './icons.css';

// import { ReactComponent as Svg } from '../../Assets/Images/HS-logo-figure.svg';

function Icons() {

    const icons = getTopLevelMenuItems();
    const navList = icons.map(icon => {
        return <div key={icon.url} className="ico">
            <NavLink to={icon.url}>
                <i className={icon.icon}></i>
                <p>{icon.title}</p>
            </NavLink>
        </div>
    });

    // <Svg fill="#894ab3" width="19%" />

    return (
        <div className="icons">
            {navList}
            <div className="ico">
                <NavLink exact to="/fragor">
                    <i className="fas fa-question"></i>
                    <p>Frågor</p>
                </NavLink>
            </div>
        </div>
    );
}

export default Icons;
