import React from 'react';
import { NavLink } from 'react-router-dom';
import { getTopLevelMenuItems } from '../../WebContent/logic/getContentData';
import './menu.css';

function TopMenu() {

    const menuItems = getTopLevelMenuItems();
    const navList = menuItems.map(icon => {
        return <NavLink key={icon.url} to={icon.url}>
            <i className={icon.icon}></i>
            {icon.title}
        </NavLink>
    });

    return (
        <div className="top-menu">
            <NavLink exact to="/">
                <i className="fas fa-home"></i>
                Hem
            </NavLink>
            {navList}
            <NavLink exact to="/fragor">
                <i className="fas fa-question"></i>
                Frågor
            </NavLink>
        </div>
    );
}

export default TopMenu;