import React from 'react';
import './page.css';

import Icons from '../Components/Home/Icons';
import Logo from '../Assets/Images/blue-512.png'

function Home() {
    return (
        <div className="page home">
            <div className="middle-col">
                <h1>Välkommen till Leva med HS!</h1>
                <p>
                    <b>Hidradenitis Suppurativa, HS</b>, är en kronisk autoinflammatorisk hudsjukdom som kan ha en stor inverkan på livet.
                    Vi är ca 90 000 personer som lever med HS och många har fått vänta länge på att få en diagnos och den vård de behöver.
                    Det finns en okunskap om sjukdomen både bland oss som drabbas och i vården och många väljer att dölja sin sjukdom och lider i det tysta.
                    Vi syns inte, men vi finns och vi är många.
                </p>
                <p>
                    <b>”Leva med HS”</b> är en guide för dig som lever med HS. Den innehåller information, tips och
                    råd som förhoppningsvis kan ge dig stöd och vägledning i vardagen så att du får den hjälp du
                    behöver för att leva ett bra liv.
                </p>
                <hr />
                <Icons />
                <hr />
                <p>
                    Kontakta oss gärna om du har funderingar eller behöver någon att prata med. Vi vet att det kan vara värdefullt att dela tankar,
                    känslor och erfarenheter med någon annan som är eller har varit i samma situation.
                </p>
                <p>
                    HS-föreningen Sverige<br />
                    <a href="https://www.hsforeningensverige.se" target="_blank" rel="noopener noreferrer">www.hsforeningensverige.se</a>
                </p>
                <p><i>Materialet är faktagranskat av dermatolog Hassan Killasli, verksamhetschef vid Älvsjö Hudmottagning.</i></p>
                <p><i>HS-föreningen Sverige har tagit fram detta material i samarbete med AbbVie.</i></p>
                <p style={{ textAlign: 'center' }}>
                    <a href="https://www.hsforeningensverige.se" target="_blank" rel="noopener noreferrer">
                        <img style={{ maxWidth: '330px', marginTop: '30px' }} src={Logo} alt="HS-föreningen logotyp" />
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Home;
