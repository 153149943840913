import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import LeftMenu from '../Components/Menus/LeftMenu';
import './page.css';

function Generic({ content, parentUrl }) {

    const [pageContent, setPageContent] = useState();

    fetch(content).then((response) => response.text()).then((text) => {
        setPageContent(text);
    })

    return (
        <div className="page generic">
            <div className="left-col">
                <LeftMenu parentUrl={parentUrl} />
            </div>
            <div className="middle-col">
                <ReactMarkdown source={pageContent} />
            </div>
        </div>
    );
}

export default Generic;
