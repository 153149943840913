import P02About from '../MarkdownFiles/02_om.md';
import P02About_03What from '../MarkdownFiles/03_om_vad.md';
import P02About_04Symptoms from '../MarkdownFiles/04_om_symptom.md';
import P02About_05Why from '../MarkdownFiles/05_om_varfor.md';
import P02About_06Skov from '../MarkdownFiles/06_om_skov.md';
import P02About_07Diagnosis from '../MarkdownFiles/07_om_diagnos.md';
import P02About_08Treatment from '../MarkdownFiles/08_om_behandling.md';
import P02About_09Connection from '../MarkdownFiles/09_om_koppling.md';
import P10Dialogue from '../MarkdownFiles/10_dialog.md';
import P10Dialogue_11Goals from '../MarkdownFiles/11_dialog_mal.md';
import P10Dialogue_12Where from '../MarkdownFiles/12_dialog_var.md';
import P10Dialogue_13Contacts from '../MarkdownFiles/13_dialog_kontakter.md';
import P10Dialogue_14Rights from '../MarkdownFiles/14_dialog_rattigheter.md';
import P15Life from '../MarkdownFiles/15_livet.md';
import P15Life_16Lifestyle from '../MarkdownFiles/16_livet_livsstil.md';
import P15Life_17Physical from '../MarkdownFiles/17_livet_fysisk.md';
import P15Life_18Workout from '../MarkdownFiles/18_livet_traning.md';
import P15Life_19Diet from '../MarkdownFiles/19_livet_kost.md';
import P15Life_20Smoking from '../MarkdownFiles/20_livet_rokning.md';
import P15Life_21Pain from '../MarkdownFiles/21_livet_smarta.md';
import P15Life_22Sleep from '../MarkdownFiles/22_livet_somn.md';
import P15Life_23Depression from '../MarkdownFiles/23-24_livet_nere.md';
import P15Life_25Selfcare from '../MarkdownFiles/25_livet_egenvard.md';
import P26Support from '../MarkdownFiles/26_support.md';
import P26Support_27Diagnosis from '../MarkdownFiles/27_support_diagnos.md';
import P26Support_28Discussion from '../MarkdownFiles/28_support_prata.md';
import P26Support_29Work from '../MarkdownFiles/29_support_arbetsliv.md';
import P26Support_30Focus from '../MarkdownFiles/30_support_fokus.md';
import P31HSForeningen from '../MarkdownFiles/31_HS_sverige.md';
import P31HSForeningen_33Medlem from '../MarkdownFiles/32_HS_sverige_medlem.md';
import P31HSForeningen_34Hitta from '../MarkdownFiles/32_HS_sverige_hitta.md';


const children02About = [
    {
        title: "Vad är HS?",
        url: "/omhs/vadarhs",
        content: P02About_03What
    },
    {
        title: "De vanligaste symtomen",
        url: "/omhs/symtom",
        content: P02About_04Symptoms
    },
    {
        title: "Varför får man HS?",
        url: "/omhs/varfor",
        content: P02About_05Why
    },
    {
        title: "Skov",
        url: "/omhs/skov",
        content: P02About_06Skov
    },
    {
        title: "Diagnos",
        url: "/omhs/diagnos",
        content: P02About_07Diagnosis
    },
    {
        title: "Behandlingar",
        url: "/omhs/behandlingar",
        content: P02About_08Treatment
    },
    {
        title: "Koppling till andra sjukdomar",
        url: "/omhs/koppling",
        content: P02About_09Connection
    }
];

const children10Dialogue = [
    {
        title: "Mål och uppföljning",
        url: "/dialogmedvarden/malouppfoljning",
        content: P10Dialogue_11Goals
    },
    {
        title: "Vart vänder jag mig?",
        url: "/dialogmedvarden/vartvanderjagmig",
        content: P10Dialogue_12Where
    },
    {
        title: "Dina kontakter i vården",
        url: "/dialogmedvarden/kontakter",
        content: P10Dialogue_13Contacts
    },
    {
        title: "Dina rättigheter",
        url: "/dialogmedvarden/rattigheter",
        content: P10Dialogue_14Rights
    }
];

const children15Life = [
    {
        title: "Livsstilens betydelse",
        url: "/livetmedhs/livsstil",
        content: P15Life_16Lifestyle
    },
    {
        title: "Fysisk aktivitet",
        url: "/livetmedhs/aktivitet",
        content: P15Life_17Physical
    },
    {
        title: "Tips på träning vid HS",
        url: "/livetmedhs/traning",
        content: P15Life_18Workout
    },
    {
        title: "Kost",
        url: "/livetmedhs/kost",
        content: P15Life_19Diet
    },
    {
        title: "Rökning",
        url: "/livetmedhs/rokning",
        content: P15Life_20Smoking
    },
    {
        title: "Smärta",
        url: "/livetmedhs/smarta",
        content: P15Life_21Pain
    },
    {
        title: "Trötthet/Sömn",
        url: "/livetmedhs/somn",
        content: P15Life_22Sleep
    },
    {
        title: "Nedstämdhet",
        url: "/livetmedhs/nedstamdhet",
        content: P15Life_23Depression
    },
    {
        title: "Egenvård",
        url: "/livetmedhs/egenvard",
        content: P15Life_25Selfcare
    }
];

const children26Support = [
    {
        title: "Att få en diagnos",
        url: "/stodosupport/attfadiagnos",
        content: P26Support_27Diagnosis
    },
    {
        title: "Våga prata",
        url: "/stodosupport/vagaprata",
        content: P26Support_28Discussion
    },
    {
        title: "Arbetsliv/skola",
        url: "/stodosupport/arbetsliv",
        content: P26Support_29Work
    },
    {
        title: "Fokus på det som fungerar",
        url: "/stodosupport/fokus",
        content: P26Support_30Focus
    },
];

const children31HSForeningen = [
    {
        title: "Bli medlem",
        url: "/hsforeningen/medlem",
        content: P31HSForeningen_33Medlem
    },
    {
        title: "Följ oss",
        url: "/hsforeningen/foljoss",
        content: P31HSForeningen_34Hitta
    }
]

const contentStructure = [
    {
        title: "Om HS",
        url: "/omhs",
        content: P02About,
        icon: "fas fa-heartbeat",
        children: children02About
    },
    {
        title: "Dialog med vården",
        url: "/dialogmedvarden",
        content: P10Dialogue,
        icon: "fas fa-user-md",
        children: children10Dialogue
    },
    {
        title: "Livet med HS",
        url: "/livetmedhs",
        content: P15Life,
        icon: "far fa-heart",
        children: children15Life
    },
    {
        title: "Stöd & support",
        url: "/stodosupport",
        content: P26Support,
        icon: "far fa-comments",
        children: children26Support
    },
    {
        title: "HS-föreningen",
        url: "/hsforeningen",
        icon: "fas fa-street-view",
        content: P31HSForeningen,
        children: children31HSForeningen
    }
];

export default contentStructure;