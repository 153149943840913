import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getAllContentLinks } from '../../WebContent/logic/getContentData';
import './menu.css';

function MobileMenu({ parentUrl }) {

    const [showMenu, setShowMenu] = useState(false);

    const menuItems = getAllContentLinks();

    const navList = menuItems.map(item => {
        let itemIcon = null;
        let isParent = 'child';
        if (item.icon) {
            itemIcon = <i className={item.icon}></i>
            isParent = 'parent';
        }
        return <div key={item.url} className={isParent}>
            <NavLink exact to={item.url} onClick={() => handleShowMenu()}>
                {itemIcon}
                {item.title}
            </NavLink>
        </div>;
    });

    const handleShowMenu = () => {
        const showing = showMenu;
        setShowMenu(!showing);
    }

    return (
        <div className="mobile-menu">
            {!showMenu ?
                <p className="toggle-menu" onClick={() => handleShowMenu()}><i className="fas fa-bars"></i> Meny</p>
                :
                <>
                    <div className="parent">
                        <NavLink exact to="/" onClick={() => handleShowMenu()}>
                            <i className="fas fa-home"></i>
                            Hem
                        </NavLink>
                    </div>
                    {navList}
                    <div className="parent">
                        <NavLink exact to="/fragor" onClick={() => handleShowMenu()}>
                            <i className="fas fa-question"></i>
                            Frågor
                        </NavLink>
                    </div>
                </>
            }
        </div>
    );
}

export default MobileMenu;