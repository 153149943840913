import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { getRoutes } from './WebContent/logic/getContentData';
import './Assets/app.css';
import './Assets/app.responsive.css';

import Header from './Components/Common/Header';
import MenuSwitch from './Components/Menus/MenuSwitch';
import Footer from './Components/Common/Footer';
import Cookies from './Components/Common/Cookies';
import Home from './Pages/Home';
import Generic from './Pages/Generic';
import Questions from './Pages/Questions';

function App() {

    const routes = getRoutes();

    const routesList = routes.map(route => {
        return <Route exact key={route.url} path={route.url}>
            <Generic content={route.content} parentUrl={route.parent} />
        </Route>
    });

    return (
        <div className="app">
            <Router>
                <div className="content-area">
                    <div className="header">
                        <Header />
                        <MenuSwitch />
                    </div>
                    <div className="content">
                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            {routesList}
                            <Route exact path="/fragor">
                                <Questions />
                            </Route>
                        </Switch>
                    </div>
                </div>
                <div className="fixed-footer">
                    <Cookies />
                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            </Router>
        </div>
    );
}

export default App;
