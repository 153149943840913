import React from 'react';
import './page.css';

function Questions() {
    return (
        <div className="page home">
            <div className="middle-col">
                <h1>Frågor och funderingar</h1>
                <p>
                    Vi hoppas att du har hittat den information du söker. Här har vi samlat frågor och funderingar från innehållet i Leva med HS.
                </p>
                <blockquote>
                    <p>
                        Varje upplevelse av en sjukdom är unik. Vilka symtom du uppfattar som värre än andra är det bara du som vet. Det kan också vara helt andra saker
                        än läkemedel du vill ha hjälp med. Det kan exempelvis vara samtalsstöd, hjälp med att få sexlivet att fungera eller hjälp med smärtan.
                    </p>
                    <p>
                        <strong>Mål och uppföljning av behandling</strong>
                    </p>
                    <ul>
                        <li>Vilka delar av din sjukdom känns viktigast att få hjälp med?</li>
                        <li>Vilken typ av hjälp behöver du förutom behandling/läkemedel?</li>
                    </ul>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/dialogmedvarden/malouppfoljning"><i className="fas fa-arrow-right"></i> Mål och uppföljning av behandling</a>
                    </p>
                </blockquote>
                <blockquote>
                    <p><strong>Vart vänder jag mig?</strong></p><p>Hur nöjd är du med den vård du har idag?</p>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/dialogmedvarden/vartvanderjagmig"><i className="fas fa-arrow-right"></i> Vart vänder jag mig?</a>
                    </p>
                </blockquote>
                <blockquote>
                    <p><strong>Tips på träning vid HS</strong></p><ul><li>Hur mycket rör du dig idag?</li><li>Om du behöver öka mängden rörelse i din vardag, vilken typ av rörelse är lättast att få in och vad fungerar för dig? Mindre sittande, mer vardagsmotion, mer träning?</li></ul>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/livetmedhs/traning"><i className="fas fa-arrow-right"></i> Tips på träning vid HS</a>
                    </p>
                </blockquote>
                <blockquote>
                    <p><strong>Vilken betydelse har en hälsosam kost?</strong></p><ul><li>Hur äter du idag?</li><li>Behöver du göra några förändringar i din kost? Vad vill du förändra?</li></ul>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/livetmedhs/kost"><i className="fas fa-arrow-right"></i> Vilken betydelse har en hälsosam kost?</a>
                    </p>
                </blockquote>
                <blockquote>
                    <p><strong>Nedstämdhet</strong></p><ul><li>Hur ser balansen ut i ditt liv? </li><li>Vad är det som tar energi och gör att du kommer i obalans och vad är det som ger energi och gör att du kommer i balans igen? </li></ul>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/livetmedhs/nedstamdhet"><i className="fas fa-arrow-right"></i> Nedstämdhet</a>
                    </p>
                </blockquote>
                <blockquote>
                    <p><strong>Egenvård - Vad kan jag göra själv?</strong></p><ul><li>Vad fungerar för dig?</li><li>Fundera på om du ser något mönster i vad som triggar igång din HS. Kost, värme, kyla, hormoner, menstraution etc. Skriv gärna dagbok så blir det lättare att komma ihåg.</li></ul>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/livetmedhs/egenvard"><i className="fas fa-arrow-right"></i> Egenvård - Vad kan jag göra själv?</a>
                    </p>
                </blockquote>
                <blockquote>
                    <p><strong>Att få en diagnos</strong></p><ul><li>Hur har det varit för dig från det att du fick de första symtomen till där du är idag? </li><li>Vad har hjälpt dig framåt vad har stjälpt dig eller gjort det svårare för dig?</li></ul>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/stodosupport/attfadiagnos"><i className="fas fa-arrow-right"></i> Att få en diagnos</a>
                    </p>
                </blockquote>
                <blockquote>
                    <p><strong>Våga prata</strong></p><ul><li>Vem pratar du om din sjukdom med idag? </li><li>Vad känns lagom att säga på jobbet/i skolan och vad passar att säga till de närmaste vännerna?</li></ul>
                    <p style={{ textAlign: 'right' }}>
                        <a href="/stodosupport/vagaprata"><i className="fas fa-arrow-right"></i> Våga prata</a>
                    </p>
                </blockquote>
            </div>
        </div>
    );
}

export default Questions;
