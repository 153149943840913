import React from 'react';
import './common.css';

import AjFull from '../../Assets/Images/aj_full_reverse.png';
import Ribbon from '../../Assets/Images/ribbon-192.png';

function Header() {
    return (
        <div className="header-content">
            <div className="left">
            </div>
            <div className="middle">
                <img src={AjFull} alt="Att leva med HS" />
                <div className="title">
                    <h1>Att leva <img src={Ribbon} alt="HS ribbon" /> med HS</h1>
                    <h2>En guide för dig som lever<br />med Hidradenitis Suppurativa, HS</h2>
                </div>
            </div>
            <div className="right"></div>
        </div>
    );
}

export default Header;
