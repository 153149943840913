import React, { useState, useEffect } from 'react';
import './menu.css';

import MobileMenu from './MobileMenu';
import TopMenu from './TopMenu';

function MenuSwitch() {

    const [menuType, setMenuType] = useState('small');

    let switchMenu = () => {
        if (window.innerWidth < 670) {
            setMenuType('small');
        } else {
            setMenuType('large');
        }
    };

    useEffect(() => {
        switchMenu();
        window.addEventListener("resize", switchMenu);
        return () => window.removeEventListener("resize", switchMenu);
    }, []);

    return (
        <div className="menu-switch">
            {menuType === 'small' ?
                <MobileMenu />
                :
                <TopMenu />
            }
        </div>
    );
}

export default MenuSwitch;