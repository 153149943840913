import React from 'react';
import './common.css';

// import Logo from '../../Assets/Images/logo-blue.png';

function Footer() {
    return (
        <div className="footer-inner">
            {/* <a href="https://www.hsforeningensverige.se" target="_blank" rel="noopener noreferrer">
                <img alt="HS-föreningen Sverige logotyp" src={Logo} />
            </a> */}
            <a href="https://www.facebook.com/hsforeningensverige" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/hsforeningensverige/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.hsforeningensverige.se" target="_blank" rel="noopener noreferrer">
                <i className="copyright">© 2020 HS-föreningen Sverige</i>
            </a>
        </div>
    );
}

export default Footer;
