import React, { useState } from 'react';
import './common.css';

function Footer() {

    const [showCookies, setShowCookies] = useState(true);

    const closeCookies = () => {
        setShowCookies(false);
    }

    return (
        <>
            {showCookies &&
                <div className="cookies">
                    Denna sida använder cookies för att hålla reda på besöksstatistik. Genom att fortsätta godkänner du användandet av dessa.
                    <i className="fas fa-times" onClick={() => closeCookies()}></i>
                </div>
            }
        </>
    );
}

export default Footer;
