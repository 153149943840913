import Content from './ContentStructure';


const getAllContent = () => {
    return Content;
};

const flattenContentStructure = () => {
    let contentArray = [];
    const content = getAllContent();
    content.forEach(element => {
        const myObj = {};
        myObj.title = element.title;
        myObj.url = element.url;
        myObj.content = element.content;
        myObj.parent = element.url;
        contentArray.push(myObj);

        const children = element.children || [];

        children.forEach(child => {
            child.parent = element.url;
            contentArray.push(child);
        });
    });

    return contentArray;
};

export const getRoutes = () => {
    const content = flattenContentStructure();
    const routes = content.map(item => {
        const myObj = {};
        myObj.url = item.url;
        myObj.content = item.content;
        myObj.parent = item.parent;
        return myObj;
    });

    return routes;
}

export const getTopLevelMenuItems = () => {
    const content = getAllContent();
    const topLevelItems = content.map(item => {
        const myObj = {};
        myObj.url = item.url;
        myObj.title = item.title;
        myObj.icon = item.icon;
        return myObj;
    });

    return topLevelItems;
};

export const getSubLevelMenuItems = (parentUrl) => {
    const content = getAllContent();
    const parent = content.find(i => i.url === parentUrl);
    const children = parent.children || [];
    const subLevelItems = children.map(item => {
        const myObj = {};
        myObj.url = item.url;
        myObj.title = item.title;
        return myObj;
    });

    const parentObj = {};
    parentObj.url = parent.url;
    parentObj.title = parent.title;
    parentObj.icon = parent.icon;

    subLevelItems.unshift(parentObj)

    return subLevelItems || [];
};

export const getAllContentLinks = () => {
    const menuItems = [];
    const topLevelItems = getTopLevelMenuItems();
    topLevelItems.forEach(item => {
        const subLevelItems = getSubLevelMenuItems(item.url);
        subLevelItems.forEach(item => {
            menuItems.push(item);
        });
    });

    return menuItems;
}