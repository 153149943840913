import React from 'react';
import { NavLink } from 'react-router-dom';
import { getSubLevelMenuItems } from '../../WebContent/logic/getContentData';
import './menu.css';

function LeftMenu({ parentUrl }) {

    const menuItems = getSubLevelMenuItems(parentUrl);
    const navList = menuItems.map(item => {
        let itemIcon = null;
        if(item.icon) {
            itemIcon = <i className={item.icon}></i>
        }
        return <div key={item.url}>
            <NavLink exact to={item.url}>
                {itemIcon}
                {item.title}
            </NavLink>
        </div>;
    });

    return (
        <div className="left-menu">
            {navList}
        </div>
    );
}

export default LeftMenu;